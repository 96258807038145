.share-content-container {
  position: absolute;
  width: 60px;
  top: $space-md-2;
  left: 0;

  @include breakpoint(sm) {
    position: static;
    display: flex;
    width: 100%;
    padding-top: $space-reg-2;
    justify-content: center;
  }

  .share-button-icon {
    display: block;
    width: 60px;
    height: 60px;
    padding: $space-reg-1;
    margin-bottom: $space-sm-1;
    background-color: $button-1;

    @include breakpoint(sm) {
      width: 48px;
      height: 48px;
      padding: $space-sm-1;
      margin-right: $space-sm-1;
      margin-bottom: 0;
    }
  }
}