h1, h2, h3, h4 {
  font-family: 'Chonburi', cursive;
}
h1, h2, h3, h4, h5 {
  line-height: $lh-lg;
  margin: 0;
}
h2 {
  font-size: $fs-lg;

  @include breakpoint(xs) {
    font-size: $fs-md;
  }
}
h3 {
  font-size: $fs-md;
}
h4 {
  font-size: $fs-reg;

  &.content-title {
    font-size: 26px;
  }
}
p {
  font-size: $fs-xs;
  margin: 0;
}

a {
  cursor: pointer;
  text-decoration: none;

  &.head-link {
    font-family: 'Chonburi', cursive;
    font-size: $fs-reg;
    color: $button-1;
  }
}

.section-title {
  &.with-star {
    display: flex;
    align-items: center;
    
    .star {
      display: block;
      width: 20px;
    }
    h4 {
      padding: 0 $space-sm-2;
    }
  }

  &.with-space-lg-2 {
    padding: $space-lg-2 0 $space-md-2 0;
  }
}

.text-center {
  text-align: center;
}

.gold-2 { color: $gold-2; }
.champagne { color: $champagne; }
.button-1 { color: $button-1; }
.button-1-hover { color: $button-1-hover; }
.grey-dark-2 { color: $grey-dark-2; }
.grey-dark-3 { color: $grey-dark-3; }
.grey-light-1 { color: $grey-light-1; }
.grey-light-2 { color: $grey-light-2; }
.grey-light-3 { color: $grey-light-3; }
.silver { color: $silver; }
.grey-1 { color: $grey-1; }
.black { color: $black; }
.black-000 { color: $black-000; }
.white { color: $white; }
.colors-3-status-green { color: $colors-3-status-green; }
.colors-3-status-blue { color: $colors-3-status-blue; }
.colors-3-status-orange { color: $colors-3-status-orange; }
.dark-bg-no-border { color: $dark-bg-no-border; }
.error { color: $error; }