@import './themes/variables';
@import './themes/color-palette';
@import './themes/mixin/responsive';
@import './themes/mixin/line-clamp';
@import '../node_modules/swiper/swiper';
@import '../node_modules/swiper/components/effect-fade/effect-fade';

*, ::after, ::before {
  box-sizing: border-box;
}
body {
  font-family: 'Kanit', sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: $grey-dark-2;
  line-height: $lh-xl;

  &.active-full-height {
    height: 100vh;
    overflow: hidden;
  }

  &.website-search-active {
    padding-top: 80px;
  }
}

.landing-info-sticky {
  .content-stick {
    position: fixed !important;
    top: 80px !important;
  }

  &.content-stick-at-bottom {
    .content-stick {
      top: unset !important;
    }
  }

  .content-scroll {
    width: 100% !important;
    padding-left: 50% !important;
  }

  @include breakpoint(sm) {
    .content-stick {
      position: relative !important;
      top: 0 !important;
    }
    &.content-stick-at-bottom {
      .content-stick {
        top: 0 !important;
        bottom: unset !important;
      }
    }

    .content-scroll {
      width: 100% !important;
      padding-left: $space-reg-1 !important;
    }
  }
}

.website-search-active {
  header {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(47, 45, 42, 0.8);
  }
}

.full-w {
  width: 100%;
}