.container {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  z-index: 1;
  padding-left: $space-reg-1;
  padding-right: $space-reg-1;
}

.wrapper-reg {
  max-width: 990px;
  margin: 0 auto;
}

.content-wrapper {
  max-width: 1150px;
  margin: 0 auto;
  padding: 0 $space-lg-2;
  
  @include breakpoint(sm) {
    padding: 0;
  }
}

.page-content-container {
  position: relative;
  padding-top: 80px;
  overflow-x: hidden;
  height: 100%;

  .page-content {
    padding-bottom: 160px;
    min-height: calc(100vh - 192px);

    @include breakpoint(sm) {
      padding-bottom: $space-lg-2;
    }
    @include breakpoint(xs) {
      padding-bottom: $space-md-2;
    }

    &.with-top-space {
      padding-top: 64px
    }

    &.less-space {
      padding-bottom: $space-lg-2;
    }

    &.less-xs-space {
      padding-bottom: $space-md-2;
    }
  }
}

.highlight-container {
  padding-bottom: $space-lg-1;

  @include breakpoint(sm) {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}

.content-type-block {
  align-items: center;

  .title .star {
    display: inline-block;
  }
  .title {
    padding: 0 $space-xs-2;
  }
  .star {
    width: 15px;

    img {
      width: 15px;
    }
  }

  &.gray {
    .title {
      color: $grey-1;
    }
  }
}