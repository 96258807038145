.form-control {
  height: 50px;
  font-size: $fs-xs;
  font-weight: $fs-w-base;
  line-height: $lh-xl;
  color: $dark-bg-no-border;
  padding: $space-sm-3 $space-reg-2;
  border: solid 1px $silver;

  &.invalid {
    border-color: $error;
  }
}

.form-control {
  outline: none;

  &:-webkit-input-placeholder,  &:-moz-placeholder, &:-ms-input-placeholder, &:-moz-placeholder {
    color: $dark-bg-no-border; 
  }

  &[type="text"], &[type="email"], &[type="tel"] {
    display: block;
    width: 100%;
    color: $dark-bg-no-border;
    background-color: $white;
  }
}

select, textarea {
  &.form-control {
    display: block;
    width: 100%;
  }
}

textarea {
  min-height: 96px;
}

.invalid-text {
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 300;
  color: $error;
}