.content-card-item {
  display: flex;
  flex-direction: column;
  padding-bottom: $space-lg-1;

  @include breakpoint(sm) {
    padding-bottom: $space-md-2;
  }

  .content-card-img {
    padding-bottom: $space-reg-1;
  }
  .content-title {
    padding-top: $space-reg-1;
    @include text-clamp(3);

    &.with-2-line-clamp {
      @include text-clamp(2);
    }

    &.half-pt {
      padding-top: $space-sm-1;
    }

    &.card-link-title {
      font-size: 18px;
      font-weight: $fs-w-bold;
    }
  }
  .content-desc {
    padding-top: $space-reg-1;
    @include text-clamp(5);
  }
  .content-link {
    margin-top: $space-reg-1;
  }

  .content-card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
}