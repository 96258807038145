.content-wysiwyg {
    font-family: 'Kanit', sans-serif !important;

    table {
        width: 100% !important;
        display: block;

        thead, tbody, td {
            display: block;
        }
        tr {
            width: 100%;
            display: flex;
            margin-left: -10rem;
            margin-right: -10rem;
        }
        td {
            flex-grow: 1;
            flex-basis: 0;
            min-width: 0;
            padding-left: 10rem;
            padding-right: 10rem;
        }
    }

    img {
        max-width: 100%;
        height: auto !important;
    }

    @include breakpoint(md) {
        table {
            tr {
                flex-wrap: wrap;
            }
            td {
                flex-basis: auto;
                width: 100%;
            }
        }
    }
}
