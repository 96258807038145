.swiper-container {
  .swiper-button-prev, .swiper-button-next {
    position: absolute;
    width: 38px;
    height: 24px;
    top: 50%;
    z-index: 2;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    transform: translateY(-50%);
  }
  .swiper-button-prev {
    left: $space-reg-1;
    background-image: url('/images/share/gal-arr-left.svg');
  }
  .swiper-button-next {
    right: $space-reg-1;
    background-image: url('/images/share/gal-arr-right.svg');
  }

  .swiper-pagination {
    display: flex;
    position: absolute;
    bottom: $space-reg-2;
    left: $space-reg-2;
    z-index: 2;

    .swiper-pagination-bullet {
      display: block;
      width: 12px;
      height: 12px;
      margin: 0 0 0 8px;
      opacity: 0.35;
      background-color: $white;

      &.swiper-pagination-bullet-active {
        background-color: $gold-2;
        opacity: 1;
      }
    }
  }
}

.vertical-swiper {
  height: 100%;

  .swiper-pagination {
    position: absolute;
    top: 50%;
    right: $space-md-1;
    bottom: unset;
    left: unset;
    transform: translateY(-50%);
    flex-direction: column;

    .swiper-pagination-bullet {
      margin: 0 0 $space-sm-1 0;
    }

    @include breakpoint(sm) {
      flex-direction: row;
      top: unset;
      right: unset;
      bottom: $space-reg-3;
      left: 50%;
      transform: translateX(-50%);

      .swiper-pagination-bullet {
        margin: 0 $space-sm-1 0 0;
      }
    }
  }
}