.pagination-container {
  display: flex;
  justify-content: center;
  padding-top: $space-reg-1;

  .pagination {
    display: flex;
    align-items: stretch;
  
    .page-item {
      width: 38px;
      color: $dark-bg-no-border;
      text-align: center;
      text-decoration: none;
      padding: $space-reg-1 $space-sm-2;
  
      @include breakpoint(xs) {
        width: 32px;
        padding: $space-sm-3 $space-sm-1;
      }

      &.active {
        color: $white;
        background-color: $button-1;
      }
  
      &.page-arr {
        display: flex;
        width: 80px;
        margin-left: $space-sm-1;
        margin-right: $space-sm-1;
        justify-content: center;
        background-color: $white;
        box-shadow: 0 2px 6px 0 rgba(69, 73, 91, 0.08);
  
        img {
          width: 16px;
        }

        @include breakpoint(xs) {
          width: 50px;
        }

        &.page-disable {
          opacity: .5;
        }
      }
    }
  }
}
