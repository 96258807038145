.link {
  display: inline-block;
  font-size: $fs-xs;
  text-decoration: none;

  &.link-btn-default {
    color: $button-1;
    height: 30px;
    border-bottom: solid 1px $button-1;
  }
  &.link-btn-white {
    color: $white;
    height: 30px;
    border-bottom: solid 1px $white;
  }
}