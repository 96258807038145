.img-responsive {
  width: 100%;
  
  > div {
    position: unset !important;
  }

  img {
    object-fit: contain;
    width: 100% !important;
    position: relative !important;
    height: unset !important;
  }
}
.img-block {
  display: block;
  line-height: 0;
}
.full-w {
  width: 100%;
}

.position-relative {
  position: relative;
}

.align-items-center {
  align-items: center;
}
.align-items-baseline {
  align-items: baseline;
}

.justified-content-center {
  justify-content: center;
}
.justified-content-space-between {
  justify-content: space-between;
}
.justified-content-end {
  justify-content: flex-end;
}

.bg-cover-c {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.bg-contain-c {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.flex-wrap {
  flex-wrap: wrap;
}
.d-block {
  width: 100%;
  display: block !important;
}
.show-dt {
  display: block;
  @include breakpoint(sm) {
    display: none;
  }
}
.show-mb {
  display: none;
  @include breakpoint(sm) {
    display: block;
  }
}