.btn {
  background-color: transparent;
  cursor: pointer;
  text-align: center;
  transition: background-color ease .25s;

  &.primary-btn {
    background-color: $button-1;
    color: $white;
    border: solid 1px $button-1;
    padding: $space-sm-3 $space-reg-2;

    &.no-underline {
      text-decoration: none !important;
    }

    &:hover {
      background-color: $button-1-hover;
    }
  }
}