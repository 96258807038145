.content-title-container {
  display: flex;
  align-items: center;

  svg {
    margin: 0 $space-sm-2;

    &:first-child {
      margin-left: -$space-sm-2;
    }
    &:last-child {
      margin-right: -$space-sm-2;
    }
  }

  .title {
    &.title-gold {
      color: $button-1;
    }
    &.title-white {
      color: $white;
    }
  }
}