.banner-container {
  position: relative;

  .swiper-wrapper {
    height: auto;
  }
  .swiper-button-next, .swiper-button-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    width: 40px;
    height: 24px;
  
    &:after {
      display: none;
    }
  }
  .swiper-button-next {
    background-image: url('/images/banner/banner-arrow-next.svg');
    
  }
  .swiper-button-prev {
    background-image: url('/images/banner/banner-arrow-prev.svg');
  }
}

.swiper-container-visible {
  .swiper-container {
    overflow: visible;
  }
}