.relate-content-container {
  padding-top: $space-lg-2;
  
  .relate-list-container {
    display: flex;
    flex-wrap: wrap;
    padding-top: $space-md-1;

    .relate-item {
      width: 33.3333% !important;

      @include breakpoint(sm) {
        width: 50% !important;
      }
      @include breakpoint(xs) {
        width: 100% !important;
      }
    }
  }

  .relate-swipe-list-container {
    display: flex;
    flex-wrap: wrap;
    padding-top: $space-md-1;
    margin-left: -15px;
    margin-right: -15px;

    .swiper-container {
      display: flex;
      width: 100%;
    }

    .swiper-wrapper {
      display: flex;
      align-items: stretch;

      .swiper-slide {
        display: flex;
      }
    }

    .relate-item {
      width: 100% !important;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}